import React from 'react';
import cn from '../../../lib/classNames';
import SectionTitle from './SectionTitle';
import Disclaimer from './Disclaimer/Disclaimer';
import Emergency from './Emergency/Emergency';
import Locations from './Locations/Locations';
import LocationMap from './Locations/LocationMap';
import hospitalImg from './images/hospital.svg';
import safetyImg from './images/safety.svg';
import unsafetyImg from './images/unsafety.svg';
import Alert from '../../../components/Alert/Alert';
import Header from './Header';

import styles from './RiskSafetySummary.module.scss';

export const MAP_GROUP = [
	{
		icon: '',
		btnText: 'All',
		field: 'all',
		backgroundImage: '',
		getList: () => [],
		helpMessage: () => '',
	},
	{
		icon: 'dangerous',
		btnText: 'High Risk areas',
		field: 'high_risk_locations',
		backgroundImage: unsafetyImg,
		getList: (list) => list?.areas?.high_risk_locations,
		helpMessage: (data) => data?.areas?.help_message_risk,
	},
	{
		icon: 'encrypted',
		btnText: 'Safest places',
		field: 'safest_areas',
		backgroundImage: safetyImg,
		getList: (list) => list?.areas?.safest_areas,
		helpMessage: (data) =>
			(!data.areas.safest_areas.length && data.areas.help_message_safest) || '',
	},
	{
		icon: 'emergency',
		btnText: 'Hospitals',
		field: 'hospitals',
		backgroundImage: hospitalImg,
		getList: (list) => list?.hospitals,
		helpMessage: () => '',
	},
];

const ADVISORY_LEVELS = {
	advisory_levels_1: 1,
	advisory_levels_2: 2,
	advisory_levels_3: 3,
	advisory_levels_4: 4,
};

export default function RiskSafetySummary(props) {
	const {
		data,
		locationCenter,
		showWarningMessage = false,
		destination,
	} = props;

	const addTypeMarkers = () => {
		MAP_GROUP.forEach((e) => {
			if (e.field !== 'all') {
				if (e.getList(data) && e.getList(data).length) {
					e.getList(data).forEach((item) => {
						item.backgroundImage = e.backgroundImage;
					});
				}
			}
		});
	};

	addTypeMarkers();

	const advisories = data?.advisories;

	const DEFAULT_DESTINATION_TEXT = 'the destination';
	const warningMessage = `Travel to ${
		destination || DEFAULT_DESTINATION_TEXT
	} is currently subject to significant risks due to active conflict. Information on the safest, most hazardous areas, and critical locations is unreliable and continuously evolving. Stay informed with our latest updates and consult local official sources. Exercise extreme caution.`;

	const bluf = data?.summary?.bluf;
	const body = data?.summary?.body;

	return (
		<section
			className={cn(
				styles['risk-safety-summary-container'],
				'risk-safety-summary-container'
			)}
		>
			<div className={styles.content}>
				{advisories &&
					advisories.advisory_level > ADVISORY_LEVELS.advisory_levels_2 &&
					advisories.warning_details && (
						<Alert
							type='errors'
							title='ATTENTION: ONE OR MORE GOVERNMENTS ARE EITHER RESTRICTING OR ADVISING AGAINST TRAVEL TO THIS DESTINATION'
							text={advisories.warning_details}
						/>
					)}
				<Header
					className={cn(styles.section, styles['section-widget-wrap'])}
					bluf={bluf}
					body={body}
				/>
				<SectionTitle
					title='Critical Numbers'
					subTitle='Including Local Emergency and Law Enforcement Information'
				/>
				<Emergency
					className={cn(styles.section, styles['section-widget-wrap'])}
					data={data?.locations}
				/>
				<SectionTitle
					title='Significant Locations'
					subTitle='Including Selected Hospitals, Safe Areas, High-Risk Neighborhoods, etc.'
				/>

				{showWarningMessage && (
					<Alert type='warning' icon='warning_amber' text={warningMessage} />
				)}

				<Locations
					className={cn(styles.section, styles['section-widget-wrap'])}
					data={data}
				/>

				<LocationMap
					className={cn(styles.section, styles['section-widget-wrap'])}
					data={data}
					locationCenter={locationCenter}
				/>

				<SectionTitle title='Important Notice to Users:' />

				<Disclaimer
					classNameTerms={cn(styles.section, styles['section-widget-wrap'])}
				/>
			</div>
		</section>
	);
}
