import React, { useState } from 'react';

import cn from '../../../../lib/classNames';
import TabButton from '../TabButton';
import Hospitals from './Hospitals';
import SafestPlaces from './SafestPlaces';
import HighRisk from './HighRisk';
import { MAP_GROUP } from '../RiskSafetySummary';

import styles from './Locations.module.scss';

export default function Locations(props) {
	const { className, data } = props;

	const [tab, setTab] = useState(MAP_GROUP[1].field);

	const renderTabButton = MAP_GROUP.map((e) => {
		if (e.field !== 'all') {
			return (
				<TabButton
					key={e.btnText}
					className={cn(styles['tab-button'], `btn-${e.field}`)}
					iconBefore={
						e.icon && (
							<span
								className={`material-symbols-rounded btn-icon icon-${e.icon}`}
							>
								{e.icon}
							</span>
						)
					}
					onClick={() => setTab(e.field)}
					active={tab === e.field}
				>
					{e.btnText}
				</TabButton>
			);
		}
		return null;
	});

	return (
		<>
			<section className={cn(styles.locations, className)}>
				<header className={styles.header}>
					<div className={styles['btn-wrap']}>{renderTabButton}</div>
				</header>
				<div className={styles['content-wrap']}>
					{tab === MAP_GROUP[1].field && (
						<HighRisk
							data={MAP_GROUP[1].getList(data)}
							helpMessage={MAP_GROUP[1].helpMessage(data)}
						/>
					)}
					{tab === MAP_GROUP[2].field && (
						<SafestPlaces
							data={MAP_GROUP[2].getList(data)}
							helpMessage={MAP_GROUP[2].helpMessage(data)}
						/>
					)}
					{tab === MAP_GROUP[3].field && (
						<Hospitals data={MAP_GROUP[3].getList(data)} />
					)}
				</div>
			</section>
		</>
	);
}
